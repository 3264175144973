


























































































































































import useMisc from "@/use/misc";
import { defineComponent, reactive, ref, watch } from "@vue/composition-api";
import { AxiosInstance } from "axios";
import { v4 as uuid } from "uuid";

export default defineComponent({
  components: {
    MGenerateDiscountCode: () =>
      import("@/components/molecules/m-generate-discount-code.vue"),
  },
  setup(_, { root }) {
    const { goBack, copyAllCodes } = useMisc({ root });
    const form = ref<any>(null);
    const model = reactive({
      name: "",
      discountType: "percent",
      value: "",
      startDate: "",
      endDate: "",
      discountCodeType: "multiple use",
      amount: "",
      discountCode: [] as any,
      status: false,
      isGlobal: false,
      assignedToModule: null,
    });

    const state = reactive({
      unlimitedAmount: true,
      codeGenerator: false,
      singleCode: "",
      loading: false,
      success: false,
      error: false as boolean | number,
      valid: false,
    });

    const { discountType } = useMisc({ root });

    const getCodes = (value: string[]) => {
      model.discountCode = value.map((e) => ({ value: e }));
    };

    const removeCode = (index: number) => {
      model.discountCode.splice(index, 1);
    };

    const copySingleCode = (index: number) => {
      navigator.clipboard.writeText(model.discountCode[index].value);
    };

    const addSingleCode = () => {
      model.discountCode.push({
        value: state.singleCode,
      });
      state.singleCode = "";
    };

    watch(
      () => model.discountCodeType,
      () => {
        model.discountCode = [];
      }
    );

    watch(
      () => model.isGlobal,
      () => {
        if (model.isGlobal === false) {
          model.assignedToModule = null;
        }
      }
    );

    const getErrorMessage = (code: number) => {
      switch (code) {
        case 403:
          return `${root.$tc("layout.errors.noPermission")}`;
        case 404:
          return `${root.$tc("event.panel.config.discounts.add.error404")}`;
        case 409:
          return `${root.$tc("event.panel.config.discounts.add.error409")}`;
        case 500:
          return `${root.$tc("layout.errors.500")}`;
        default:
          return `${root.$tc("layout.errors.default")}`;
      }
    };

    const onSubmit = async (action: string) => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      const data = {
        name: model.name,
        type: model.discountType,
        value: model.value,
        startDate: model.startDate || undefined,
        endDate: model.endDate || undefined,
        codeType: model.discountCodeType,
        amount:
          model.discountCodeType === "single use"
            ? null
            : state.unlimitedAmount
            ? model.amount
            : null,
        status: model.status,
        codes:
          model.discountCodeType === "multiple use"
            ? [{ value: model.discountCode }]
            : model.discountCode,
        isGlobal: model.isGlobal || false,
        assignedToModule: model.assignedToModule || null,
      };

      state.loading = true;

      axiosInstance
        .post(`event/${root.$route.params.id}/discount`, data, {
          headers: {
            event: root.$route.params.id,
          },
        })
        .then(() => {
          state.success = true;
          state.error = false;

          root.$store.commit("snackbar/PUSH_MESSAGE", {
            id: uuid(),
            color: "primary",
            message: root.$tc("event.panel.config.discounts.add.success"),
          });
          if (action === "new") {
            model.name = "";
            model.discountType = "percent";
            model.value = "";
            model.startDate = "";
            model.endDate = "";
            model.discountCodeType = "multiple use";
            model.amount = "";
            model.discountCode = [] as any;
            model.status = false;
          } else {
            goBack("panel.event.view.discount.list");
          }
        })
        .catch((error) => {
          state.error = error.response.status;
          root.$store.commit("snackbar/PUSH_MESSAGE", {
            id: uuid(),
            color: "error",
            message: getErrorMessage(state.error as number),
          });
        })
        .finally(() => (state.loading = false));
    };

    const rules = {
      required: [(v: string) => !!v || `${root.$t("layout.misc.required")}`],
    };

    return {
      form,
      model,
      state,
      discountType,
      getCodes,
      removeCode,
      copySingleCode,
      addSingleCode,
      copyAllCodes,
      onSubmit,
      rules,
    };
  },
});
